import React, { useState, useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { BirminghamAirportAuthorityPageProps } from "./birmingham-airport-authority.d"
import ImageHero from "../../components/Hero/ImageHero"
import { BackgroundContainer } from "../../components/BackgroundContainer"
import SectionHeading from "../../components/SectionHeading/SectionHeading"
import tw from "twin.macro"
import PageHeading from "../../components/PageHeading/PageHeading"
import { StaffMember } from "../../components/Staff"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import { SEO } from "../../components/SEO"

const BirminghamAirportAuthority: React.FC<
  BirminghamAirportAuthorityPageProps
> = ({ data, location }) => {
  const { title, content, featuredImage, template } = data.wpPage
  const heroImage =
    featuredImage.node?.localFile?.childImageSharp?.gatsbyImageData
  const { staffSection } = template.aboutAirportAuthorityPage
  const subHeading = staffSection?.headline

  const [activeMemberName, setActiveMemberName] = useState("")

  const [text, setText] = useState({ subHeading })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ subHeading })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([subHeading])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      {heroImage && <ImageHero image={featuredImage} />}
      <BackgroundContainer css={tw`text-center mb-16 md:mb-24`}>
        <PageHeading heading={title} content={content} />
      </BackgroundContainer>
      {staffSection.staffMember && (
        <section css={tw`container mx-auto`}>
          <SectionHeading css={tw`mb-16`}>{text?.subHeading}</SectionHeading>
          <div
            css={[
              tw`relative grid gap-y-10 px-4 sm:grid-cols-2 sm:gap-4 lg:grid-cols-3 xl:gap-6 xl:px-6 2xl:gap-8`,
            ]}
          >
            {staffSection.staffMember?.map(
              ({ name, title, image, bio, linkedinLink }, i) => (
                <StaffMember
                  name={name}
                  title={title}
                  image={image}
                  linkedinLink={linkedinLink}
                  bio={bio}
                  activeMemberName={activeMemberName}
                  setActiveMemberName={(name: string) =>
                    setActiveMemberName(name)
                  }
                  key={i}
                />
              )
            )}
          </div>
        </section>
      )}
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      template {
        ... on WpTemplate_BirminghamAirportAuthority {
          templateName
          aboutAirportAuthorityPage {
            staffSection {
              headline
              staffMember {
                bio
                category
                linkedinLink
                name
                title
                image {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(quality: 97)
                    }
                  }
                }
              }
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

export default BirminghamAirportAuthority
