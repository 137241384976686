import React, { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import Close from "../Icons/Close"
import { StaffMemberProps } from "./Staff.d"
import { RightArrow } from "../Icons/RightArrow"
import { Image } from "../Image"
import Linkedin from "../Icons/Linkedin"
import { Modal } from "../Modal"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers.js")
import { LanguageContext } from "../../context/Language"
import { Link } from "../Link"

const StaffMember: React.FC<StaffMemberProps> = ({
  name,
  title,
  image,
  linkedinLink,
  bio,
  activeMemberName,
  setActiveMemberName,
}) => {
  const [isMobile, setIsMobile] = useState(null)
  const readBioText = "Read Bio"
  const [text, setText] = useState({ name, title, bio, readBioText })
  const { language } = useContext(LanguageContext)

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 768)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(window.innerWidth < 768)
    }
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  useEffect(() => {
    if (language === "en") {
      setText({ name, title, bio, readBioText })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          name,
          title,
          bio,
          readBioText,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <>
      <article
        css={[
          tw`w-full max-w-[32rem] max-h-[35rem] relative overflow-hidden text-white`,
        ]}
        key={name}
      >
        <Image
          src={image?.localFile?.childImageSharp?.gatsbyImageData}
          publicURL={image?.localFile?.publicURL}
          alt={image?.altText}
          css={[
            tw`w-full h-full object-cover object-top min-h-[26rem] sm:min-h-[22rem] sm:min-h-[30rem]`,
          ]}
          objectPosition={"top"}
        />
        <div
          css={[
            tw`absolute left-0 bottom-0 w-full px-4 py-5 bg-blue-7/10 transition-all duration-500 max-h-full overflow-auto md:min-h-[10rem] lg:min-h-[13.75rem] xl:min-h-[10rem]`,
            activeMemberName === name && tw`bg-blue-9/10 md:bg-blue-7/10`,
          ]}
        >
          <p
            css={[
              tw`pb-1.5 text-2xl`,
              activeMemberName === name && tw`inline-block mr-2`,
              (linkedinLink && activeMemberName != name) && tw`mr-6`
            ]}
          >
            {name}
          </p>
          {linkedinLink && (
            <Link
              to={linkedinLink}
              css={[
                tw`absolute right-4 top-4 opacity-100 visible transition-all duration-300`,
                activeMemberName === name &&
                  tw`relative inline-block inset-auto my-auto pb-2.5 align-middle`,
              ]}
            >
              <Linkedin css={tw`w-6 h-6`} />
            </Link>
          )}
          <p
            css={[tw`text-lg`, bio && tw`mr-24`]}
            dangerouslySetInnerHTML={{ __html: text?.title }}
          />
          {bio && (
            <button
              aria-label={`read ${name}'s bio`}
              aria-controls="activeMemberBio activeMemberBioMobile"
              aria-expanded={activeMemberName === name}
              onClick={() => setActiveMemberName(name)}
              className="group"
              css={[
                tw`absolute right-5 bottom-6 flex items-center justify-between text-lg underline opacity-100 p-1`,
                tw`transition-all hover:text-lightBlue focus:text-lightBlue focus:outline-none focus:border-dashed focus:border focus:border-blue-150 focus:rounded-sm active:border-0`,
                activeMemberName === name && tw`opacity-0 invisible`,
              ]}
              style={{
                transition:
                  activeMemberName === name
                    ? "opacity 0.4s, color 0.4s, visibility 0.4s"
                    : "opacity 0.4s 0.3s, color 0.4s, visibility 0.4s",
              }}
            >
              {text?.readBioText}
              <RightArrow
                styling={[
                  tw`max-w-0 w-4 h-4 ml-1 group-hover:max-w-screen group-focus:max-w-screen`,
                ]}
              />
            </button>
          )}
          <button
            onClick={() => setActiveMemberName("")}
            aria-label={`close ${name}'s bio`}
            style={{ outlineColor: "#AED1D3", outlineOffset: "8px" }}
            tabIndex={0}
            css={[
              tw`absolute right-6 top-5 md:hidden opacity-100 visible transition-all duration-300`,
              activeMemberName !== name && tw`invisible opacity-0`,
            ]}
          >
            <Close color="white" iconStyle={tw`w-5 h-5`} />
          </button>
          {bio && (
            <p
              css={[
                tw`max-h-0 text-sm md:text-lg mt-4 transition-max-h duration-max-h-hide overflow-hidden md:hidden`,
                activeMemberName === name &&
                  tw`max-h-screen duration-max-h-show`,
              ]}
              dangerouslySetInnerHTML={{ __html: text?.bio }}
            />
          )}
        </div>
      </article>
      {!isMobile && (
        <Modal
          open={activeMemberName === name}
          onClose={() => setActiveMemberName("")}
          backdropWhite={true}
          closeWhite={true}
          css={[
            tw`text-white bg-blue-300 flex flex-row p-0 overflow-hidden h-[35rem]`,
          ]}
        >
          {image && (
            <Image
              src={image?.localFile?.childImageSharp?.gatsbyImageData}
              publicURL={image?.localFile?.publicURL}
              alt={image?.altText}
              css={tw`h-full object-cover sm:min-w-[24rem]`}
              objectPosition={"top"}
            />
          )}
          <div css={tw`h-full p-6 overflow-y-scroll lg:py-12 lg:px-16`}>
            <p css={[tw`pb-1.5 text-2xl inline-block mr-4`]}>{name}</p>
            {linkedinLink && (
              <Link
                to={linkedinLink}
                target="_blank"
                css={tw`inline-block pb-2.5 align-middle`}
              >
                <Linkedin css={tw`w-7 h-7`} />
              </Link>
            )}
            <p
              css={[tw`text-lg`]}
              dangerouslySetInnerHTML={{ __html: text?.title }}
            />
            <p
              css={[tw`text-sm md:text-base lg:text-lg mt-4`]}
              dangerouslySetInnerHTML={{ __html: text?.bio }}
            />
          </div>
        </Modal>
      )}
    </>
  )
}

export default StaffMember
